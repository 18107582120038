import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import type { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskMode,
  TaskType,
  VoiceEmotion,
} from "@heygen/streaming-avatar";
import { useMemoizedFn, usePrevious } from "ahooks";
import { useMessage } from '../../context/MessageContext';

interface VoiceConversationAvatarProps {
  full: boolean;
}

const style = {
  marginRight: "8px",
  display: "flex",
  height: 80,
  minHeight: 80,
  borderRadius: 18,
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  animation: "3s linear .1s",
};

const avatarStyle = {
  width: 80,
  height: 80,
  minWidth: 80,
  minHeight: 80,
  borderRadius: 100,
};

const avatarFullStyle = {
  width: 160,
  height: 160,
  minWidth: 160,
  minHeight: 160,
  borderRadius: 200,
};

const fullStyle = {
  marginRight: "8px",
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  animation: "3s linear .1s",
};

async function generateToken() {
  const REACT_APP_HEYGEN_SECRET_ACCESS_KEY =
    process.env.REACT_APP_HEYGEN_SECRET_ACCESS_KEY;
  if (!REACT_APP_HEYGEN_SECRET_ACCESS_KEY) {
    console.error("API key is missing from .env");
    return;
  }
  try {
    if (!REACT_APP_HEYGEN_SECRET_ACCESS_KEY) {
      console.error("API key is missing from .env");
      return;
    }
    const res = await fetch(
      `https://api.heygen.com/v1/streaming.create_token`,
      {
        method: "POST",
        headers: {
          "x-api-key": REACT_APP_HEYGEN_SECRET_ACCESS_KEY,
        },
      }
    );

    const data = await res.json();
    return new Response(data.data.token, {
      status: 200,
    });
  } catch (error) {
    console.error("Error retrieving access token:", error);

    return new Response("Failed to retrieve access token", {
      status: 500,
    });
  }
}

export default function VoiceConversationAvatar({
  full,
}: VoiceConversationAvatarProps) {
    const { messages } = useMessage();
  
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [debug, setDebug] = useState<string>();
  const [knowledgeId, setKnowledgeId] = useState<string>("");
  const [avatarId, setAvatarId] = useState<string>("");
  const [language, setLanguage] = useState<string>("en");

  const [data, setData] = useState<StartAvatarResponse>();
  const [text, setText] = useState<string>("");
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);
  const [chatMode, setChatMode] = useState("text_mode");
  const [isUserTalking, setIsUserTalking] = useState(false);

  async function fetchAccessToken() {
    try {
      const response = await generateToken();
      const token = await response?.text() || '';
      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }

    return "";
  }

  async function startSession(avatarId: string = "Shawn_Therapist_public") {
    setIsLoadingSession(true);
    const newToken = await fetchAccessToken();

    avatar.current = new StreamingAvatar({
      token: newToken,
      basePath: "https://api.heygen.com",
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log("Avatar started talking", e);
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      endSession();
    });
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready:", event.detail);
      setStream(event.detail);
    });
    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      console.log(">>>>> User started talking:", event);
      setIsUserTalking(true);
    });
    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      console.log(">>>>> User stopped talking:", event);
      setIsUserTalking(false);
    });
    try {
      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.Low,
        avatarName: avatarId,
        knowledgeId: knowledgeId, // Or use a custom `knowledgeBase`.
        voice: {
          rate: 1.5, // 0.5 ~ 1.5
          emotion: VoiceEmotion.EXCITED,
          // elevenlabsSettings: {
          //   stability: 1,
          //   similarity_boost: 1,
          //   style: 1,
          //   use_speaker_boost: false,
          // },
        },
        language: language,
        disableIdleTimeout: true,
      });

      setData(res);
      setChatMode("voice_mode");
    } catch (error) {
      console.error("Error starting avatar session:", error);
    } finally {
      setIsLoadingSession(false);
    }
  }
  async function handleSpeak() {
    setIsLoadingRepeat(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    // speak({ text: text, task_type: TaskType.REPEAT })
    await avatar.current
      .speak({ text: text, taskType: TaskType.REPEAT, taskMode: TaskMode.SYNC })
      .catch((e) => {
        setDebug(e.message);
      });
    setIsLoadingRepeat(false);
  }
  async function handleInterrupt() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    await avatar.current.interrupt().catch((e) => {
      setDebug(e.message);
    });
  }
  async function endSession() {
    await avatar.current?.stopAvatar();
    setStream(undefined);
  }

  const handleChangeChatMode = useMemoizedFn(async (v) => {
    if (v === chatMode) {
      return;
    }
    if (v === "text_mode") {
      avatar.current?.closeVoiceChat();
    } else {
      await avatar.current?.startVoiceChat();
    }
    setChatMode(v);
  });

  const previousText = usePrevious(text);
  // useEffect(() => {
  //   if (!previousText && text) {
  //     avatar.current?.startListening();
  //   } else if (previousText && !text) {
  //     avatar?.current?.stopListening();
  //   }
  // }, [text, previousText]);

  useEffect(() => {
    if (!messages || messages[messages.length - 1]?.from_user) {
      return;
    }
    avatar.current?.speak({
      text: messages[messages.length - 1]?.message,
      taskType: TaskType.REPEAT,
      taskMode: TaskMode.SYNC,
    });
  }, [messages]);

  useEffect(() => {
    // setAvatarId("candace_expressive_20240910");
    startSession();
    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug("Playing");
      };
    }
  }, [mediaStream, stream]);

  return (
    <Box sx={full ? fullStyle : style}>
      {!full ? (
        <img
          src="/vera.png"
          alt="Vera Icon"
          style={full ? avatarFullStyle : avatarStyle}
        />
      ) : isLoadingSession && !stream ? (
        <Box>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "24px",
              fontWeight: 500,
              width: 500,
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Welcome to Vera Voice Mode! In this mode, you can fully interact
            with our system using your voice, enjoying a seamless, hands-free
            experience
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "24px",
              fontWeight: 500,
              width: 500,
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            ...
          </Typography>
        </Box>
      ) : (
        <video
          ref={mediaStream}
          autoPlay
          playsInline
          style={
            full
              ? { ...avatarFullStyle, objectFit: "cover" }
              : { ...avatarStyle, objectFit: "cover" }
          }
        >
          <track kind="captions" />
        </video>
      )}
    </Box>
  );
}
