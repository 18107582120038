import React from 'react';
import { useTranscription } from '../hooks/useTranscription';

const TranscribeApp: React.FC = () => {
  const { transcript, error, recording, startRecording, stopRecording } =
    useTranscription();

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Live Transcription</h1>

      <button
        className={`px-4 py-2 rounded-lg font-medium ${
          recording
            ? 'bg-red-500 hover:bg-red-600 text-white'
            : 'bg-blue-500 hover:bg-blue-600 text-white'
        }`}
        onClick={recording ? stopRecording : startRecording}
      >
        {recording ? 'Stop Recording' : 'Start Recording'}
      </button>

      {error && (
        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="mt-4 p-4 border rounded-lg bg-white shadow min-h-[200px] text-left whitespace-pre-wrap">
        {transcript || 'Your transcription will appear here...'}
      </div>
    </div>
  );
};

export default TranscribeApp;
