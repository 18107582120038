import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useTheme } from '@mui/material';
import { styled } from '@mui/system';
import ChatBox from './pages/chatbox';
import Loading from './pages/loading';
import TranscribeApp from './components/TranscriptAudio';
import DirectTranscribe from './components/DirectTranscribe';
import TicketComponent, { TicketStatus } from './components/ticket/Ticket';
import { MessageProvider } from './context/MessageContext';

const App: React.FC = () => {
  const theme = useTheme();
  return (
    <MessageProvider>
      <Router>
        <div
          className="app"
          style={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <div className="content">
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Loading />} />
              <Route path="/chatbox" element={<ChatBox />} />
              <Route path="/transcript-audio" element={<TranscribeApp />} />
              <Route path="/direct-transcribe" element={<DirectTranscribe />} />
              <Route
                path="/ticket"
                element={
                  <TicketComponent
                    id="13524"
                    description="Lorem ipsum dolor sit amet, consecter adipiscing elit"
                    creationDate="12/02/2025"
                    status={TicketStatus.PENDING}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </MessageProvider>
  );
};

export default App;
