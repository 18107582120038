import { useState, useRef, useCallback, useEffect } from 'react';

interface TranscriptionResult {
  transcript: string;
  isPartial: boolean;
  confidence: number;
}

interface UseTranscriptionReturn {
  transcript: string;
  error: string;
  recording: boolean;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
}
const URI =
  process.env.REACT_APP_TRANSCRIBE_API_BASE_URL || 'ws://localhost:5555/';
export const useTranscription = (): UseTranscriptionReturn => {
  const [transcript, setTranscript] = useState<string>('');
  const [recording, setRecording] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const socketRef = useRef<WebSocket | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);

  const cleanup = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }

    setRecording(false);
  }, []);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  const handleTranscriptionResult = useCallback((data: TranscriptionResult) => {
    setTranscript((prev) => {
      if (data.isPartial) {
        const parts = prev.split(' ');
        parts[parts.length - 1] = data.transcript;
        return parts.join(' ');
      }
      return prev + ' ' + data.transcript;
    });
  }, []);

  const startRecording = async () => {
    try {
      setError('');
      setTranscript('');

      socketRef.current = new WebSocket(URI);

      socketRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.error) {
            setError(data.error);
            cleanup();
            return;
          }
          if (data.transcript) {
            handleTranscriptionResult(data);
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };

      // Wait for WebSocket connection
      await new Promise<void>((resolve, reject) => {
        if (!socketRef.current)
          return reject(new Error('No WebSocket connection'));
        socketRef.current.onopen = () => resolve();
        socketRef.current.onerror = (error) => reject(error);
      });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
      streamRef.current = stream;

      audioContextRef.current = new AudioContext({
        sampleRate: 16000,
      });

      const sourceNode =
        audioContextRef.current.createMediaStreamSource(stream);
      const processorNode = audioContextRef.current.createScriptProcessor(
        1024,
        1,
        1
      );

      processorNode.onaudioprocess = (audioProcessingEvent) => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          const audioData = audioProcessingEvent.inputBuffer.getChannelData(0);
          const pcmData = new Int16Array(audioData.length);
          for (let i = 0; i < audioData.length; i++) {
            pcmData[i] = Math.max(-1, Math.min(1, audioData[i])) * 0x7fff;
          }
          socketRef.current.send(pcmData.buffer);
        }
      };

      sourceNode.connect(processorNode);
      processorNode.connect(audioContextRef.current.destination);
      setRecording(true);
    } catch (err) {
      console.error('Error starting recording:', err);
      setError(
        'Failed to start recording. Please check your microphone permissions.'
      );
      cleanup();
    }
  };

  return {
    transcript,
    error,
    recording,
    startRecording,
    stopRecording: cleanup,
  };
};
