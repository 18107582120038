import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Drawer, Box } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import VoiceConversationActions from './VoiceConversationActions';
import VoiceConversationCounter from './VoiceConversationCounter';
import VoiceConversationMessages from './VoiceConversationMessages';
import VoiceConversationAvatar from './VoiceConversationAvatar';
import VoiceConversationHeaderActions from './VoiceConversationHeaderActions';
import VoiceConversationTranscript from './VoiceConversationTranscript';
import { useAwsTranscribe } from '../../hooks/useAwsTranscribe';
import { useMessage } from '../../context/MessageContext';
import { v4 as uuidv4 } from 'uuid';
import { useConversations } from '../../hook/useConversations';
import { queryClient } from '../../utils/http';
import { useMutation } from '@tanstack/react-query';
import { useSocketMessage } from '../../hooks/useSocketMessage';
import { useLanguage } from '../../LanguageProvider';
import useSpeechSynthesis from '../../hook/useSpeechSynthesis';
import { DOUBLE_TEXTING_NUMBER } from '../../constants';
import GeneratingAnswer from '../GeneratingAnswer';

const style = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  padding: '24px 24px 8px 24px',
};

interface VoiceConversationProps {
  disabled?: boolean;
  conversationId: string;
  notAllowContinueConversation?: boolean;
  content?: any;
  newMessage?: any;
}

export default function VoiceConversation(props: VoiceConversationProps) {
  const [open, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const { transcript, startRecording, stopRecording } = useAwsTranscribe();
  const { messages, addMessage, updateMessage } = useMessage();
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [questionInSection, setQuestionInSection] = useState<number>(0);
  const isRecordingRef = useRef<boolean>(false);
  const hasSynthesizedRef = useRef<boolean>(false);
  const conversationNewIdRef = useRef<string | null>();
  const { createConversation, sendConversationMessages } = useConversations();
  const { language } = useLanguage();
  const [isResponding, setIsResponding] = useState(false);
  const { synthesizeSpeech, stopSpeech, isSpeaking, setIsSpeaking } =
    useSpeechSynthesis({});
  const [isMuted, setIsMuted] = useState(false);
  const handleStartRecording = async () => {
    isRecordingRef.current = true;
    await startRecording();
  };

  const handleStopRecording = () => {
    isRecordingRef.current = false;
    stopRecording();
  };

  const {
    messages: socketMessages,
    setIsResponding: setSocketResponding,
    resetMessages,
  } = useSocketMessage({
    conversationId: props.conversationId,
    onDone: () => {
      setIsResponding(false);
      setQuestionInSection(0);
    },
  });

  const { messages: socketStreamMessage } = useSocketMessage({
    conversationId: 'streaming_' + (props.conversationId || ''),
  });

  useEffect(() => {
    const isSpeakingCondition =
      socketMessages?.message &&
      !hasSynthesizedRef.current &&
      !isMuted &&
      open &&
      !isSpeaking;
    if (isSpeakingCondition) {
      hasSynthesizedRef.current = true;
      synthesizeSpeech(socketMessages.message, 'en-US');
      resetMessages();
    }
  }, [socketMessages, synthesizeSpeech, resetMessages, isMuted]);

  useEffect(() => {
    if (!socketMessages) {
      return;
    }

    const lastMessage = messages[0];
    const isNewMessage = lastMessage?.id !== socketMessages.message_id;

    if (!isNewMessage) {
      return;
    }

    if (!messages.length) {
      addMessage({
        id: socketMessages.message_id || uuidv4(),
        message: socketMessages?.message || '',
        sender_id: '-1',
        from_user: false,
        language: language,
      });
    } else if (messages[0].from_user) {
      addMessage({
        id: socketMessages.message_id || uuidv4(),
        message: socketMessages?.message || '',
        sender_id: '-1',
        from_user: false,
        language: messages[0].language || language,
      });
    } else {
      const updateLastMessage = messages[0];
      updateMessage(updateLastMessage.id, {
        message: socketMessages?.message || '',
        sender_id: updateLastMessage?.sender_id || '-1',
        from_user: updateLastMessage?.from_user === true,
        language: updateLastMessage?.language || language,
      });
    }
    setSocketResponding(false);
  }, [socketMessages, language, setSocketResponding]);

  useEffect(() => {
    if (!socketStreamMessage) {
      return;
    }

    const lastMessage = messages[0];
    const isNewStreamMessage =
      lastMessage?.message !== socketStreamMessage.message;

    if (!isNewStreamMessage) {
      return;
    }

    if (lastMessage?.from_user) {
      addMessage({
        id: uuidv4(),
        message: socketStreamMessage?.message || '',
        language,
        sender_id: '-1',
        from_user: false,
      });
    } else {
      const updatedMessage = messages[0];
      updateMessage(updatedMessage.id, {
        message: updatedMessage.message + socketStreamMessage?.message,
        sender_id: updatedMessage?.sender_id || '-1',
        from_user: false,
        language: updatedMessage?.language || language,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketStreamMessage, language]);

  useEffect(() => {
    if (questionInSection >= 2) {
      setIsInputDisabled(true);
    }
    setIsInputDisabled(false);
  }, [questionInSection]);
  const createNewConversations = useMutation({
    mutationFn: async ({
      reference_conversation_id,
      name,
    }: {
      reference_conversation_id?: string;
      name?: string;
    }) => {
      const response = await createConversation({
        name,
        reference_conversation_id,
      });
      return response;
    },
  });

  const { mutate } = useMutation({
    mutationFn: async ({
      conversation_id,
      message,
    }: {
      conversation_id: null | string;
      message: string;
    }) => {
      setQuestionInSection((prev) => prev + 1);

      const { status } = await sendConversationMessages({
        conversation_id,
        message,
        is_double_texting: questionInSection >= DOUBLE_TEXTING_NUMBER,
      });
      if (status === 202) {
        await queryClient.invalidateQueries({ queryKey: ['conversations'] });
      }
      hasSynthesizedRef.current = false;

      // Add timeout to reset questionInSection if no message received

      // setTimeout(() => {
      //   if (questionInSection > 0) {
      //     setQuestionInSection(0);
      //   }
      // }, QUESTION_SECTION_TIMEOUT);

      return status;
    },
    onError: (error) => {
      console.error('Error during message sending:', error);
      setIsResponding(false);
      setIsInputDisabled(false);
    },

    onMutate: () => {
      setIsResponding(true);
    },
  });

  const handleSendMessage = async (message: string) => {
    if (!message.trim() || isInputDisabled) return;
    // if (!message.trim()) return;

    setIsInputDisabled(true);
    const currentMessage = message;

    try {
      let currentConversationId = props.conversationId || null;
      if (!currentConversationId) {
        const response: any = await createNewConversations.mutateAsync({
          name: '',
        });
        if (response.id) {
          conversationNewIdRef.current = response.id;
          currentConversationId = response.id;
          await queryClient.invalidateQueries({ queryKey: ['conversations'] });
        }
      }

      const userMessage = {
        id: uuidv4(),
        message: currentMessage,
        sender_id: '1',
        from_user: true,
        language: language,
      };

      addMessage(userMessage);
      mutate({
        conversation_id: currentConversationId,
        message: currentMessage,
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setIsInputDisabled(false);
    }
  };

  const handleMuteSpeaker = () => {
    setIsMuted((prev) => !prev);
    if (!isMuted) {
      // If we're turning mute on, stop any current speech
      stopSpeech();
    }
  };

  return (
    <div>
      <Fragment>
        <Tooltip title="Voice conversation">
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              color: 'inherit',
            }}
          >
            <GraphicEqIcon />
          </IconButton>
        </Tooltip>
        <Drawer
          open={open}
          sx={{
            padding: '8px',
          }}
          anchor="bottom"
          onClose={() => setOpen(false)}
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                width: '100%',
                gap: '24px',
              }}
            >
              <VoiceConversationHeaderActions
                onClose={() => setOpen(false)}
                onSetFullsize={() => setShowMessage(!showMessage)}
                full={!showMessage}
              />
              <VoiceConversationCounter />
              <VoiceConversationAvatar
                full={!showMessage}
              />
              <Box
                sx={{
                  overflowY: 'auto',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  padding: '0',
                }}
              >
                {showMessage && (
                  <VoiceConversationMessages
                    content={props.content}
                    messages={messages}
                  />
                )}
                {isResponding && <GeneratingAnswer />}
              </Box>
            </Box>
            <div
              onClick={async () => {
                handleStartRecording();
              }}
            ></div>
            <VoiceConversationActions
              startRecording={handleStartRecording}
              stopRecording={handleStopRecording}
              stopSpeech={stopSpeech}
              onMute={handleMuteSpeaker}
              isMuted={isMuted}
            />
          </Box>
        </Drawer>
        <VoiceConversationTranscript
          transcript={transcript}
          onSendMessage={handleSendMessage}
          disabled={props.disabled}
          // disabled={props.disabled}
          conversationId={props.conversationId}
          stopRecording={handleStopRecording}
          startRecording={handleStartRecording}
        />
      </Fragment>
    </div>
  );
}
