import React, { useState, useEffect, useRef } from 'react';

// Import the actual AWS SDK classes (not just types)
import {
  TranscribeStreamingClient,
  StartStreamTranscriptionCommand,
  AudioStream,
} from '@aws-sdk/client-transcribe-streaming';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

interface AudioState {
  audioContext: AudioContext | null;
  audioInput: MediaStreamAudioSourceNode | null;
  processor: ScriptProcessorNode | null;
  stream: MediaStream | null;
}

interface Window {
  webkitAudioContext: typeof AudioContext;
}

// AWS Configuration
const REGION = process.env.REACT_APP_AWS_REGION || 'us-east-1';
const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
const LANGUAGE_CODE = 'en-US';
const SAMPLE_RATE = 16000;

function DirectTranscribe() {
  const [status, setStatus] = useState('Not recording');
  const [transcript, setTranscript] = useState('');
  const [error, setError] = useState('');
  const [audioState, setAudioState] = useState<AudioState>({
    audioContext: null,
    audioInput: null,
    processor: null,
    stream: null,
  });

  // Refs to track transcription state
  const currentPartialRef = useRef('');
  const finalTranscriptsRef = useRef<string[]>([]);
  const transcribeClientRef = useRef<any>(null);
  const audioChunksRef = useRef<ArrayBuffer[]>([]);
  const isRecordingRef = useRef(false);

  // Clean up resources when component unmounts
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  // Update the display text based on current state
  const updateTranscript = () => {
    const finalText = finalTranscriptsRef.current.join(' ');
    const displayText = currentPartialRef.current
      ? `${finalText} ${currentPartialRef.current}`.trim()
      : finalText;

    setTranscript(displayText);
  };

  // Initialize AWS Transcribe client
  const initializeTranscribeClient = () => {
    try {
      // For development/testing, you can use credentials directly (NOT RECOMMENDED FOR PRODUCTION)
      // For production, use Cognito Identity Pool or other secure method

      if (IDENTITY_POOL_ID) {
        // Using Cognito Identity Pool (recommended for production)
        transcribeClientRef.current = new TranscribeStreamingClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: IDENTITY_POOL_ID,
          }),
        });
      } else {
        // For development only - using environment variables
        // IMPORTANT: This approach is NOT secure for production applications
        transcribeClientRef.current = new TranscribeStreamingClient({
          region: REGION,
          credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
          },
        });
      }

      return true;
    } catch (err) {
      console.error('Error initializing Transcribe client:', err);
      setError('Failed to initialize AWS Transcribe client');
      return false;
    }
  };

  // Generate audio stream for AWS Transcribe
  const getAudioStream = async function* (): AsyncGenerator<AudioStream> {
    while (isRecordingRef.current) {
      if (audioChunksRef.current.length > 0) {
        const chunk = audioChunksRef.current.shift();
        if (chunk) {
          const audioEvent = {
            AudioEvent: {
              AudioChunk: new Uint8Array(chunk),
            },
          };
          yield audioEvent as AudioStream;
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
  };

  // Start the transcription process
  const startTranscription = async () => {
    if (!transcribeClientRef.current) return;

    try {
      const command = new StartStreamTranscriptionCommand({
        LanguageCode: LANGUAGE_CODE,
        MediaEncoding: 'pcm',
        MediaSampleRateHertz: SAMPLE_RATE,
        AudioStream: getAudioStream(),
      });

      console.log('Starting transcription...');
      const response = await transcribeClientRef.current.send(command);
      console.log('Transcription response:', response);

      if (response.TranscriptResultStream) {
        for await (const event of response.TranscriptResultStream) {
          if (!isRecordingRef.current) break;

          console.log('Received transcription event:', event);
          const results = event.TranscriptEvent?.Transcript?.Results;
          if (results && results.length > 0) {
            const result = results[0];
            const alternatives = result?.Alternatives;

            if (alternatives && alternatives.length > 0) {
              const transcriptText = alternatives[0].Transcript || '';

              if (transcriptText.trim()) {
                if (result.IsPartial) {
                  currentPartialRef.current = transcriptText.trim();
                } else {
                  const finalText = transcriptText.trim();
                  if (
                    finalText &&
                    !finalTranscriptsRef.current.includes(finalText)
                  ) {
                    finalTranscriptsRef.current.push(finalText);
                    currentPartialRef.current = '';
                  }
                }
                updateTranscript();
              }
            }
          }
        }
      }
    } catch (error: any) {
      console.error('Error in transcription:', error);
      setError(`Transcription error: ${error.message || 'Unknown error'}`);

      // Fall back to simulation for development/testing
      if (process.env.NODE_ENV === 'development') {
        simulateTranscription();
      }
    }
  };

  // Simulation function for development/testing
  const simulateTranscription = () => {
    if (!isRecordingRef.current) return;

    // Simulate partial results
    setTimeout(() => {
      if (!isRecordingRef.current) return;
      currentPartialRef.current = 'This is a simulated partial transcript';
      updateTranscript();

      // Simulate final results
      setTimeout(() => {
        if (!isRecordingRef.current) return;
        const finalText = 'This is a simulated final transcript';
        finalTranscriptsRef.current.push(finalText);
        currentPartialRef.current = '';
        updateTranscript();

        // Continue simulation
        simulateTranscription();
      }, 3000);
    }, 1000);
  };

  // Start recording and transcription
  const startRecording = async () => {
    try {
      setError('');

      // Reset transcript state
      finalTranscriptsRef.current = [];
      currentPartialRef.current = '';
      audioChunksRef.current = [];
      setTranscript('');

      // Initialize AWS Transcribe client
      if (!initializeTranscribeClient()) {
        return;
      }

      // Get microphone access
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: SAMPLE_RATE,
        },
      });

      // Set up audio processing
      const AudioContextClass = (window.AudioContext ||
        (window as any).webkitAudioContext) as typeof AudioContext;

      const audioContext = new AudioContextClass({
        sampleRate: SAMPLE_RATE,
      });

      const audioInput = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(1024, 1, 1);

      processor.onaudioprocess = (e) => {
        if (!isRecordingRef.current) return;

        const float32Array = e.inputBuffer.getChannelData(0);
        const pcmData = new Int16Array(float32Array.length);

        // Convert Float32Array to Int16Array
        for (let i = 0; i < float32Array.length; i++) {
          pcmData[i] = Math.max(-1, Math.min(1, float32Array[i])) * 0x7fff;
        }

        audioChunksRef.current.push(pcmData.buffer);
      };

      audioInput.connect(processor);
      processor.connect(audioContext.destination);

      // Update state
      setAudioState({
        audioContext,
        audioInput,
        processor,
        stream,
      });

      isRecordingRef.current = true;
      setStatus('Recording');

      // Start transcription
      startTranscription();
    } catch (err) {
      console.error('Error starting recording:', err);
      setError('Failed to access microphone or start recording');
      stopRecording();
    }
  };

  // Stop recording and transcription
  const stopRecording = () => {
    isRecordingRef.current = false;

    // Clean up audio resources
    if (audioState.processor && audioState.audioInput) {
      audioState.processor.disconnect();
      audioState.audioInput.disconnect();
    }

    if (audioState.audioContext) {
      audioState.audioContext.close().catch(console.error);
    }

    if (audioState.stream) {
      audioState.stream.getTracks().forEach((track) => track.stop());
    }

    // Reset audio state
    setAudioState({
      audioContext: null,
      audioInput: null,
      processor: null,
      stream: null,
    });

    // Add any remaining partial text as final
    if (
      currentPartialRef.current &&
      !finalTranscriptsRef.current.includes(currentPartialRef.current)
    ) {
      finalTranscriptsRef.current.push(currentPartialRef.current);
      currentPartialRef.current = '';
      updateTranscript();
    }

    setStatus('Not recording');
  };

  // Clear the transcript
  const clearTranscript = () => {
    finalTranscriptsRef.current = [];
    currentPartialRef.current = '';
    setTranscript('');
  };

  return (
    <div className="max-w-3xl mx-auto p-5 font-sans">
      <h1 className="text-2xl font-semibold text-gray-800 mb-5">
        Direct AWS Transcribe
      </h1>

      <div className="mb-5">
        <div className="flex items-center gap-2.5 mb-2.5">
          Status: <span>{status}</span>
          <span
            className={`w-3 h-3 rounded-full bg-red-500 ${
              status === 'Recording' ? 'animate-pulse' : 'hidden'
            }`}
          ></span>
        </div>
        {error && (
          <div className="text-red-500 mt-1.5 p-2 bg-red-50 rounded">
            {error}
          </div>
        )}
      </div>

      <div className="flex gap-2.5 mb-5">
        <button
          onClick={startRecording}
          disabled={status === 'Recording'}
          className="px-4 py-2.5 bg-green-500 text-white rounded font-medium transition-colors hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Start Recording
        </button>
        <button
          onClick={stopRecording}
          disabled={status !== 'Recording'}
          className="px-4 py-2.5 bg-red-500 text-white rounded font-medium transition-colors hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Stop Recording
        </button>
        <button
          onClick={clearTranscript}
          className="px-4 py-2.5 bg-blue-500 text-white rounded font-medium transition-colors hover:bg-blue-600"
        >
          Clear Transcript
        </button>
      </div>

      <div className="mt-7">
        <h2 className="text-xl font-semibold text-gray-800 mb-2.5">
          Transcript
        </h2>
        <div className="min-h-[200px] p-4 border border-gray-200 rounded bg-gray-50 whitespace-pre-wrap leading-relaxed text-base">
          {transcript || (
            <span className="text-gray-400 italic">
              Your transcript will appear here...
            </span>
          )}
        </div>
      </div>

      <div className="mt-10 p-4 bg-blue-50 rounded">
        <h3 className="text-lg font-semibold text-blue-800 mt-0">
          About Direct AWS Transcribe
        </h3>
        <p className="mt-2">
          This component uses AWS Transcribe directly from the browser without a
          backend service. It requires proper AWS credentials configuration
          through Cognito Identity Pool or environment variables.
        </p>
        <div className="mt-4 p-3 bg-orange-50 border-l-4 border-orange-500 rounded">
          <strong>Important:</strong> For production use, always use Cognito
          Identity Pool or another secure authentication method. Never expose
          AWS credentials in client-side code.
        </div>
        <p className="mt-4 font-semibold">Setup Instructions:</p>
        <ol className="list-decimal pl-5 mt-2 space-y-2">
          <li>
            Install required packages:{' '}
            <code className="bg-gray-100 px-2 py-1 rounded text-sm">
              npm install @aws-sdk/client-transcribe-streaming
              @aws-sdk/credential-provider-cognito-identity
              @aws-sdk/client-cognito-identity
            </code>
          </li>
          <li>Set up a Cognito Identity Pool in AWS Console</li>
          <li>
            Configure environment variables (REACT_APP_IDENTITY_POOL_ID, etc.)
          </li>
          <li>Uncomment the AWS SDK code in this component</li>
        </ol>
      </div>
    </div>
  );
}

export default DirectTranscribe;
