import io from 'socket.io-client';

// Suppress ResizeObserver loop limit exceeded error
const resizeObserverErrorHandler = () => {
  const resizeObserverErrorDiv = document.getElementById(
    'webpack-dev-server-client-overlay-div'
  );
  const resizeObserverError = document.getElementById(
    'webpack-dev-server-client-overlay'
  );
  if (resizeObserverErrorDiv) {
    resizeObserverErrorDiv.style.display = 'none';
  }
  if (resizeObserverError) {
    resizeObserverError.style.display = 'none';
  }
};

window.addEventListener('error', (e) => {
  if (
    e.message ===
    'ResizeObserver loop completed with undelivered notifications.'
  ) {
    resizeObserverErrorHandler();
  }
});

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';

let socket: any;

export const initializeSocket = () => {
  if (!socket) {
    const TOKEN = localStorage.getItem('authToken') || '';

    socket = io(SOCKET_URL, {
      extraHeaders: {
        Authorization: TOKEN,
      },
    });

    socket.on('connect', () => {
      console.log('Socket connected!');
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected!');
    });
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    return initializeSocket();
  }
  return socket;
};
