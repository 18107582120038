import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function VoiceConversationCounter() {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  // Format the counter as MM:SS
  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        textAlign: "center",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 600, lineHeight: 1.5 }}>
        Vera AI
      </Typography>
      <Typography
        variant="body1"
        sx={{ lineHeight: "30px", fontWeight: 500, fontSize: "16px" }}
      >
        {formatTime(seconds)}
      </Typography>
    </Box>
  );
}
