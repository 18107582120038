import React from 'react';
import { Box, Typography, Avatar, Stack } from '@mui/material';

const VeraGreeting: React.FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      spacing={2}
      sx={{
        padding: 2.5,
        maxWidth: 320,
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffb6b9',
          borderRadius: '50%',
          width: 80,
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Avatar
          src="/vera.png"
          alt="Vera, customer service avatar"
          sx={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 1, mb: 0.5, fontSize: 24 }}
      >
        Hey, I'm{' '}
        <Box component="span" sx={{ color: '#e83e3e', fontSize: 24 }}>
          Vera
        </Box>
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ maxWidth: 280, fontSize: 14 }}
      >
        You can ask me all your VeryPay related questions. How can I help?
      </Typography>
    </Stack>
  );
};

export default VeraGreeting;
