import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  useMediaQuery,
  Alert,
  useTheme,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TypingIndicator from '../TypingIndicator';
import { queryClient } from '../../utils/http';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import InputBar from '../InputBar';
import MessageBubble from '../MessageBubble';
import { useConversations } from '../../hook/useConversations';
import VeraVoiceAnimation from '../VeraVoiceAnimation';
import { useLanguage } from '../../LanguageProvider';
import { useAuth } from '../../context/AuthContext';
import { getLanguageCode } from '../../utils/common';
import { useSocketMessage } from '../../hooks/useSocketMessage';
import { DOUBLE_TEXTING_NUMBER } from '../../constants';
import VeraGreeting from './VeraGreeting';
import { useMessage } from '../../context/MessageContext';

interface Message {
  from_user: boolean;
  id: string;
  language?: string;
  message: string;
  sender_id: string;
  conversation_id?: string;
}

interface ConversationChatProps {
  conversation: any;
  handleCloseChat?: () => void;
  selectNewConversationCreated: (conv?: any) => void;
  hideInputBar?: boolean;
}

const MIN_MESSAGE_COUNT = 1;

const ConversationChat: React.FC<ConversationChatProps> = ({
  conversation: conversationProps,
  selectNewConversationCreated,
  handleCloseChat,
  hideInputBar,
}) => {
  const { user } = useAuth();
  const isUserRole = user?.type !== 'guest';
  const theme = useTheme();
  const [conversationId, setConversationId] = useState<string>(
    conversationProps?.id
  );

  const [currentConversation, setCurrentConversation] = useState<any>();

  const [isLoading, setLoading] = useState(false);
  const [showHistoryButton, setShowHistoryButton] = useState(false);
  const [showHistoryMessage, setShowHistoryMessage] = useState(false);

  const [errorSystem, setErrorSystem] = useState<string | null>(null);
  const [notAllowContinueConversation, setNotAllowContinueConversation] =
    useState<boolean | undefined>(false);
  const [, setIsTyping] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const conversationIdRef = useRef<string | null>();

  const conversationNewIdRef = useRef<string | null>();
  const firstFetchNewConversation = useRef<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isVeraSpeech, setVeraSpeech] = useState<boolean>(false);
  const [isSoundMode, setSoundMode] = useState<boolean>(false);
  const { language } = useLanguage();
  const [countQuestion, setCountQuestion] = useState<number>(0);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const { messages, addMessage, updateMessage, setMessages } = useMessage();

  const {
    messages: socketMessages,
    setMessages: setSocketMessages,
    setIsResponding,
    isResponding,
  } = useSocketMessage({
    conversationId: conversationId,
    onDone: () => {
      setCountQuestion(0);
    },
  });
  //
  const { messages: socketStreamMessage } = useSocketMessage({
    conversationId: 'streaming_' + conversationId,
  });

  const {
    createConversation,
    fetchConversationMessages,
    sendConversationMessages,
  } = useConversations();

  const isDoubleTexting = useMemo(() => {
    if (countQuestion === DOUBLE_TEXTING_NUMBER && isResponding) {
      return true;
    }
    return false;
  }, [countQuestion, isResponding]);

  useEffect(() => {
    if (!socketMessages) {
      return;
    }
    setMessages((prevMessages) => {
      if (!prevMessages.length) {
        return [
          {
            id: socketMessages.message_id || uuidv4(),
            message: socketMessages?.message || '',
            sender_id: '-1',
            from_user: false,
            language: language,
          },
        ];
      }

      if (prevMessages[0].from_user) {
        return [
          {
            id: socketMessages.message_id || uuidv4(),
            message: socketMessages?.message || '',
            sender_id: '-1',
            from_user: false,
            language: prevMessages[0].language || language,
          },
          ...prevMessages,
        ];
      }
      const updateLastMessage = prevMessages.shift();
      return [
        {
          id: socketMessages.message_id || uuidv4(),
          message: socketMessages?.message || '',
          sender_id: updateLastMessage?.sender_id || '-1',
          from_user: updateLastMessage?.from_user === true,
          language: updateLastMessage?.language || language,
        },
        ...prevMessages,
      ];
    });
    setIsResponding(false);
  }, [socketMessages, language, setIsResponding]);
  useEffect(() => {
    if (!socketStreamMessage) {
      return;
    }

    setMessages((prevMessages) => {
      const lastMessage = prevMessages[0];
      if (lastMessage?.from_user) {
        return [
          {
            id: uuidv4(),
            message: socketStreamMessage?.message || '',
            language,
            sender_id: '-1',
            from_user: false,
          },
          ...prevMessages,
        ];
      } else {
        // Update the existing message with the new content
        const updatedMessage = prevMessages.shift();
        return [
          {
            id: updatedMessage?.id || uuidv4(),
            message: updatedMessage?.message + socketStreamMessage?.message,
            sender_id: updatedMessage?.sender_id || '-1',
            from_user: false,
            language: updatedMessage?.language || language,
          },
          ...prevMessages,
        ];
      }
    });
  }, [socketStreamMessage, language]);

  const fetchMessages = async ({ pageParam }: { pageParam?: string }) => {
    const response = await fetchConversationMessages({
      conversation_id: conversationId,
      page_size: 20,
      last_message_id: firstFetchNewConversation.current
        ? undefined
        : pageParam,
      signal: new AbortController().signal,
    });

    if (firstFetchNewConversation.current)
      firstFetchNewConversation.current = false;
    const shouldShowHistoryButton =
      conversationId && (!response.page_size || pageParam === undefined);
    setShowHistoryButton(!!shouldShowHistoryButton);

    return response;
  };

  const { data, error, fetchPreviousPage, isFetching, isFetchingPreviousPage } =
    useInfiniteQuery({
      queryKey: ['conversation-messages', conversationId],
      queryFn: fetchMessages,
      initialPageParam: undefined,
      getNextPageParam: () => undefined,
      getPreviousPageParam: (firstPage) =>
        firstPage?.data?.[0]?.id ?? undefined,
      enabled: !!conversationId,
    });

  const { mutate } = useMutation({
    mutationFn: async ({
      conversation_id,
      message,
    }: {
      conversation_id: null | string;
      message: string;
    }) => {
      setCountQuestion(countQuestion + 1);
      setIsInputDisabled(true);
      const { status } = await sendConversationMessages({
        conversation_id,
        message,
        is_double_texting: false,
      });
      if (status === 202) {
        await queryClient.invalidateQueries({ queryKey: ['conversations'] });
      }

      if (!conversationProps?.id) {
        selectNewConversationCreated({ id: conversationNewIdRef.current });
      }

      if (status === 500) {
        setErrorSystem('Something error!');
        setIsTyping(false);
        setCountQuestion(0);
        return;
      }
      if (status === 201) {
        setNotAllowContinueConversation(true);
        await queryClient.invalidateQueries({ queryKey: ['conversations'] });
      }

      setSocketMessages(undefined);
      setIsTyping(false);
      setIsInputDisabled(false);
    },
    onError: (error) => {
      console.error('Error during message sending:', error);
      setIsTyping(false);
      setCountQuestion(0);
      setIsInputDisabled(false);
    },
    onMutate: () => {
      setIsTyping(true);
    },
  });

  const createNewConversations = useMutation({
    mutationFn: async ({
      reference_conversation_id,
      name,
    }: {
      reference_conversation_id?: string;
      name?: string;
    }) => {
      const response = await createConversation({
        name,
        reference_conversation_id,
      });
      return response;
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      // Handle mutation error, like showing an error message
    },
    onSuccess: () => {
      // Handle success if needed
    },
  });

  const createNewConversationFromThisConversation = useCallback(
    async ({
      reference_conversation_id,
      name,
    }: {
      reference_conversation_id?: string;
      name?: string;
    }) => {
      setLoading(true);
      try {
        const response: any = await createNewConversations.mutateAsync({
          reference_conversation_id,
          name,
        });
        if (response?.id) {
          await queryClient.invalidateQueries({ queryKey: ['conversations'] });
          selectNewConversationCreated({
            id: response.id,
            reference_conversation_id: response.reference_conversation_id,
          }); // Assuming this is a function to handle the selection
        }
      } catch (error) {
        console.error('Error creating new conversation:', error);
        // You can add error handling here, such as showing a notification or updating state
      } finally {
        setLoading(false);
      }
    },
    [createNewConversations, queryClient, selectNewConversationCreated]
  );

  const handleSendMessage = async (input: string) => {
    setIsResponding(true);
    setErrorSystem(null);
    let currentConversationId = conversationProps?.id;
    if (!currentConversationId) {
      const response: any = await createNewConversations.mutateAsync({
        name: '',
      });
      if (response.id) {
        conversationNewIdRef.current = response.id;
        currentConversationId = response.id;
        await queryClient.invalidateQueries({ queryKey: ['conversations'] });
      }
    }

    if (input.trim()) {
      const userMessage: Message = {
        id: uuidv4(),
        message: input,
        sender_id: '1',
        from_user: true,
      };

      setMessages((prevMessages) => [userMessage, ...prevMessages]);
      setIsTyping(true);
      mutate({ conversation_id: currentConversationId, message: input });
      scrollToBottom(100); // Ensure this is called after sending the message
    }
  };

  const handleShowHistory = () => {
    firstFetchNewConversation.current = true;
    conversationIdRef.current = currentConversation?.reference_conversation_id;
    setConversationId(currentConversation?.reference_conversation_id);
    setShowHistoryMessage(true);
  };

  const handleHideHistoryMessage = () => {
    setMessages((prevMessages) => {
      return [
        ...prevMessages.filter((msg) => msg.conversation_id !== conversationId),
      ];
    });
    setShowHistoryMessage(false);
  };

  const scrollToBottom = (timeOut: number) => {
    const container = containerRef.current;
    if (container) {
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, timeOut);
    }
  };

  useEffect(() => {
    firstFetchNewConversation.current = true;
    setCurrentConversation(conversationProps);
    setConversationId(conversationProps?.id);
    setNotAllowContinueConversation(conversationProps?.ticket_flag);

    scrollToBottom(500);
    return () => {
      queryClient.setQueryData(['conversation-messages', conversationId], {
        pages: [],
        pageParams: [],
      });
    };
  }, [conversationProps?.id]);

  useEffect(() => {
    if (currentConversation?.id) {
    }
  }, [currentConversation?.id]);

  const getInfo = async () => {
    const token = localStorage.getItem('authToken');
    const main_url = process.env.REACT_APP_API_BASE_URL;

    try {
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      // Conditionally add token to headers if it's not null
      if (token) {
        headers['Authorization'] = token;
      }

      const response = await fetch(
        `${main_url}/api/v1/kb_conversations/${conversationId}`,
        {
          method: 'GET',
          headers: headers,
        }
      );
      const data = await response.json();
      console.log('response get conversation info', data);

      if (response.status === 200) {
        console.log('Get conversation info out successfully');
        return data.data;
      } else {
        console.error('conversation failed with status:', response.status);
      }
    } catch (e) {
      console.log('Error during get conversation:', e);
    } finally {
    }
  };

  useEffect(() => {
    if (!conversationId) {
      queryClient.setQueryData(['conversation-messages', conversationId], {
        pages: [
          {
            data: [],
            page: 1,
          },
        ],
        pageParams: [],
      });
    }

    const fetchConversationInfo = async () => {
      if (conversationId) {
        const info = await getInfo();
        setCurrentConversation(info);
      }
    };

    fetchConversationInfo();
  }, [conversationId]);

  useEffect(() => {
    if (data) {
      const container = containerRef.current;
      if (container) {
        const fetchedMessages = [
          ...data.pages.flatMap((page) =>
            page.data.map((item: any) => {
              return {
                ...item,
                conversation_id: conversationId,
              } as Message;
            })
          ),
        ];
        if (conversationIdRef.current === conversationId) {
          setMessages((prevMessages) => {
            const messageMap = new Map();
            // Add existing messages to map
            prevMessages.forEach((msg) => {
              if (msg?.id) {
                messageMap.set(msg.id, msg);
              }
            });
            // Add fetched messages to map (newer messages take precedence)
            fetchedMessages.reverse().forEach((msg) => {
              if (msg?.id) {
                messageMap.set(msg.id, msg);
              }
            });
            return Array.from(messageMap.values());
          });
        } else {
          const messageMap = new Map();
          fetchedMessages.reverse().forEach((msg) => {
            if (msg?.id) {
              messageMap.set(msg.id, msg);
            }
          });
          setMessages(Array.from(messageMap.values()));
        }
      }
      conversationIdRef.current = conversationId;
    }
  }, [data]);

  const handleScrolll = (event: React.UIEvent<HTMLDivElement>) => {
    const top =
      event.currentTarget.scrollHeight <=
      event.currentTarget.clientHeight - event.currentTarget.scrollTop + 100;

    if (top && !isFetchingPreviousPage) {
      fetchPreviousPage();
    }
  };

  let content;

  if (isFetching) {
    content = (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={40} color={'inherit'} />
      </Box>
    );
  }

  if (error) {
    content = <Typography variant="h4">{error?.message}</Typography>;
  }

  if (messages) {
    content = (
      <Box
        ref={containerRef}
        onScroll={handleScrolll}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column-reverse',
          flex: 1,
          p: 2,
          overflowY: 'auto',
          pb: 2,
        }}
      >
        {/* {isResponding && eventId === conversationId && <TypingIndicator />} */}
        {errorSystem && (
          <Box sx={{ width: '30%', alignSelf: 'center' }}>
            <Alert severity="error" onClose={() => setErrorSystem(null)}>
              {errorSystem}
            </Alert>
          </Box>
        )}
        {messages.map((message: Message) => (
          <MessageBubble
            key={message?.id || uuidv4()}
            message={message.message}
            language={getLanguageCode(
              message?.language?.split(' ').pop() || language
            )}
            isSentByUser={message.from_user}
          />
        ))}
        {isFetching ? (
          <CircularProgress
            sx={{ alignSelf: 'center' }}
            size={24}
            color={'inherit'}
          />
        ) : currentConversation?.reference_conversation_id &&
          showHistoryButton ? (
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={() => handleShowHistory()}
            sx={{
              alignSelf: 'center',
              mt: 1,
              p: 1,
              fontSize: 12,
              textTransform: 'none',
              textDecorationLine: 'underline',
            }}
          >
            See previous conversation
            <HistoryOutlinedIcon
              sx={{ ml: 1 }}
              fontSize="small"
              color="inherit"
            />
          </Button>
        ) : showHistoryMessage ? (
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={() => handleHideHistoryMessage()}
            sx={{
              alignSelf: 'center',
              mt: 1,
              p: 1,
              fontSize: 12,
              textTransform: 'none',
              textDecorationLine: 'underline',
            }}
          >
            Hide previous conversation
            <Close sx={{ ml: 1 }} fontSize="small" color="inherit" />
          </Button>
        ) : null}
      </Box>
    );
  }

  const changeSoundMode = () => {
    if (isSoundMode) {
      stopSpeech();
    }

    setSoundMode(!isSoundMode);
  };

  const stopSpeech = (): void => {
    if ('speechSynthesis' in window) {
      speechSynthesis.cancel(); // Force stop any ongoing speech
      setVeraSpeech(false);
    }
  };

  const VoiceAnimationZone = () => {
    if (!isSoundMode || !isVeraSpeech) return null;
    return (
      <Box sx={{ position: 'absolute', bottom: 70, alignSelf: 'center' }}>
        <VeraVoiceAnimation />
      </Box>
    );
  };

  const SoundMode = () => {
    return (
      <Box
        sx={{
          background: 'white',
          paddingRight: '8px',
          position: 'absolute',
          bottom: 70,
          alignSelf: 'flex-end',
          right: 0,
          boxShadow: 3,
          borderBottomLeftRadius: 100,
          borderTopLeftRadius: 100,
        }}
      >
        <IconButton aria-label="clear-input" onClick={changeSoundMode}>
          {isSoundMode ? (
            <VolumeUpRoundedIcon fontSize="medium" color="primary" />
          ) : (
            <VolumeOffRoundedIcon fontSize="medium" color="primary" />
          )}
        </IconButton>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          color: theme.palette.text.primary,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          // paddingLeft: isMobile ? (isUserRole ? '60px' : '16px') : '16px',
          flexDirection: 'row',
          height: 50,
          justifyContent: 'center',
          backgroundColor: '#F7F7F8',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{ fontWeight: 700, color: '#181818', fontSize: 16 }}
              variant="h1"
            >
              Vera AI
            </Typography>
          </Box>

          {/* <Box sx={{ position: 'absolute', right: isUserRole ? 40 : 0 }}>
                        <LanguageSelector />
                    </Box> */}
        </Box>

        {handleCloseChat ? (
          <Box>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              aria-label="clear-input"
              onClick={handleCloseChat}
            >
              <Close sx={{ fontSize: 14, color: '#000' }} />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {messages.length <= MIN_MESSAGE_COUNT ? <VeraGreeting /> : null}
      {content}
      <CreateNewConversationButton
        isVisible={!!notAllowContinueConversation}
        onClick={() =>
          createNewConversationFromThisConversation({
            reference_conversation_id: conversationProps?.id,
          })
        }
      />
      {hideInputBar ? null : (
        <Box sx={{ mx: 1, mb: 1 }}>
          <InputBar
            inputRef={inputRef}
            notAllowContinueConversation={notAllowContinueConversation}
            conversationId={conversationId}
            onSendMessage={handleSendMessage}
            content={content}
            messages={messages}
            disabled={
              notAllowContinueConversation ||
              countQuestion > DOUBLE_TEXTING_NUMBER ||
              isInputDisabled
            }
          />
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
            zIndex: 1000, // High z-index to overlay other elements
          }}
        >
          <CircularProgress size={24} color="inherit" />
        </Box>
      )}
    </Box>
  );
};

const CreateNewConversationButton = React.memo(
  ({ isVisible, onClick }: { isVisible: boolean; onClick: () => void }) => {
    if (!isVisible) return null;

    return (
      <Button
        variant="outlined"
        color="info"
        size="large"
        onClick={onClick}
        sx={{
          mt: 1,
          mb: 1,
          fontSize: 12,
          textTransform: 'none',
          alignSelf: 'center',
        }}
      >
        Click to check Ticket status
      </Button>
    );
  }
);

export default ConversationChat;
