import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { SEND_QUESTION_TIMEOUT } from '../../constants';

interface VoiceConversationTranscriptProps {
  transcript: string;
  onSendMessage?: (message: string) => void;
  disabled?: boolean;
  conversationId?: string;
  stopRecording?: () => void;
  startRecording?: () => Promise<void>;
}

const VoiceConversationTranscript: React.FC<
  VoiceConversationTranscriptProps
> = ({
  transcript,
  onSendMessage,
  disabled,
  conversationId,
  stopRecording,
  startRecording,
}) => {
  const previousTranscriptRef = useRef<string>('');
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!transcript || disabled || !onSendMessage) return;

    // Clear previous timeout if transcript changes
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // If transcript is different from previous, start new timeout
    if (transcript !== previousTranscriptRef.current) {
      previousTranscriptRef.current = transcript;

      timeoutRef.current = setTimeout(() => {
        if (transcript.trim()) {
          onSendMessage(transcript);
          // Reset transcript after sending message
          if (stopRecording) {
            stopRecording();
          }
          if (startRecording) {
            startRecording();
          }
        }
      }, SEND_QUESTION_TIMEOUT); // 2 seconds delay
    }

    // Cleanup timeout on unmount or when transcript changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [transcript, disabled, onSendMessage, stopRecording, startRecording]);

  if (!transcript) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '12px 24px',
        borderRadius: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        zIndex: 9999,
        maxWidth: '80%',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: '#333',
          fontWeight: 500,
          fontSize: '1.1rem',
        }}
      >
        {transcript}
      </Typography>
    </Box>
  );
};

export default VoiceConversationTranscript;
