import Close from "@mui/icons-material/Close";
import { Box, IconButton, Tooltip } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

interface VoiceConversationHeaderActionsProps {
  onClose: () => void;
  onSetFullsize: () => void;
  full: boolean;
}
export default function VoiceConversationHeaderActions(props: VoiceConversationHeaderActionsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Tooltip title="Close">
        <IconButton
          onClick={() => props.onClose()}
          sx={{
            color: "inherit",
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
      <Tooltip title="Close">
        <IconButton
          onClick={() => props.onSetFullsize()}
          sx={{
            color: "inherit",
          }}
        >
          {props.full ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
