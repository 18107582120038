import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Message {
  from_user: boolean;
  id: string;
  language?: string;
  message: string;
  sender_id: string;
  conversation_id?: string;
}

interface MessageContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  addMessage: (message: Message) => void;
  updateMessage: (messageId: string, updatedMessage: Partial<Message>) => void;
  clearMessages: () => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export function MessageProvider({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<Message[]>([]);
  console.log('🚀 ~ MessageProvider ~ messages:', messages);

  const addMessage = (message: Message) => {
    setMessages((prevMessages) => [message, ...prevMessages]);
  };

  const updateMessage = (
    messageId: string,
    updatedMessage: Partial<Message>
  ) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId ? { ...msg, ...updatedMessage } : msg
      )
    );
  };

  const clearMessages = () => {
    setMessages([]);
  };

  return (
    <MessageContext.Provider
      value={{
        messages,
        setMessages,
        addMessage,
        updateMessage,
        clearMessages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}

export function useMessage() {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
}
