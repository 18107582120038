import { Box, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';

interface VoiceConversationMessagesProps {
  content?: any;
  messages?: any[];
}

export default function VoiceConversationMessages(
  props: VoiceConversationMessagesProps
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  // Enhanced auto-scroll to the bottom when messages change
  useEffect(() => {
    if (!containerRef.current) return;

    const scrollToBottom = () => {
      const container = containerRef.current;
      if (!container) return;

      // Clear any existing timeout
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      // Set a new timeout to ensure the scroll happens after content is rendered
      scrollTimeoutRef.current = setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 100);
    };

    // Initial scroll
    scrollToBottom();

    // Create a MutationObserver to watch for changes in the container
    const observer = new MutationObserver(scrollToBottom);
    observer.observe(containerRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    // Cleanup function
    return () => {
      observer.disconnect();
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [props.messages]);

  const listeningContent = (
    <Typography
      variant="body1"
      sx={{
        lineHeight: '30px',
        fontWeight: 500,
        fontSize: '16px',
        textAlign: 'center',
      }}
    >
      Listening...
    </Typography>
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '0',
        maxHeight: '48vh',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '20px', // Height of the blur effect
          zIndex: 1,
        },
        '&::before': {
          top: 0,
          background:
            'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
        },
        '&::after': {
          bottom: 0,
          background:
            'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
        },
      }}
    >
      {(!props?.messages || props?.messages?.length === 0) && listeningContent}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '8px 0',
          minHeight: 'min-content',
        }}
      >
        {props?.content}
      </Box>
    </Box>
  );
}
