import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

const GeneratingAnswer: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        padding: '0px 28px',
      }}
    >
      <Avatar
        src="/vera.png"
        sx={{
          width: 24,
          height: 24,
          backgroundColor: '#e0e0e0',
        }}
      />
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: '14px',
          animation: 'blink 1.5s infinite',
          '@keyframes blink': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.5 },
            '100%': { opacity: 1 },
          },
        }}
      >
        Generating answer
      </Typography>
    </Box>
  );
};

export default GeneratingAnswer;
