import { useEffect, useState } from 'react';
import { getSocket, initializeSocket } from '../utils/socket';

interface MessageResponse {
  message: string;
  conversation_id: string;
  message_id: string;
}

interface UseSocketMessageProps {
  conversationId: string;
  onDone?: () => void;
}

export const useSocketMessage = ({
  conversationId,
  onDone,
}: UseSocketMessageProps) => {
  const socket = getSocket() || initializeSocket();

  const [messages, setMessages] = useState<MessageResponse>();
  const [isConnected, setIsConnected] = useState(socket?.connected || false);
  const [isResponding, setIsResponding] = useState(false);
  const resetMessages = () => {
    setMessages(undefined);
  };

  useEffect(() => {
    if (!socket) return;

    const eventName = `conversation_${conversationId}`;
    const onConnect = () => {
      setIsConnected(true);
      console.log('Connected');
    };
    const onDisconnect = () => setIsConnected(false);

    const onMessage = (data: MessageResponse) => {
      console.log('Message received:', data);
      setMessages(data);
      setIsResponding(false);
      onDone?.();
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on(eventName, onMessage);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off(eventName, onMessage);
    };
  }, [conversationId, onDone, socket]);

  return {
    messages,
    isConnected,
    setMessages,
    socket,
    isResponding,
    setIsResponding,
    onDone,
    eventId: conversationId,
    resetMessages,
  };
};
