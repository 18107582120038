import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
} from '@mui/material';
import ConversationList from '../../components/ConversationList';
import ConversationChat from '../../components/conversationChat/ConversationChat';
import UserInfo from '../../components/UserInfo';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useConversations } from '../../hook/useConversations';
import { useColorMode } from '../../ColorModeProvider';
import { useAuth } from '../../context/AuthContext';

const ChatBox: React.FC = () => {
  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';

  const { user } = useAuth();
  const isUserRole = user?.type !== 'guest';

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const [selectedConversation, setSelectedConversation] = useState<any>();

  const dataRef = useRef<any>();
  const conversationListRef = useRef<HTMLDivElement>(null);

  const { fetchConversations } = useConversations();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchConversation = async ({ pageParam }: { pageParam?: string }) => {
    const response = await fetchConversations({
      last_conversation_id: pageParam,
      signal: new AbortController().signal,
      page_size: 20,
    });

    return response;
  };

  const { data, error, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['conversations'],
      queryFn: fetchConversation,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage && lastPage.length > 0) {
          const lastConversation = lastPage[lastPage.length - 1];
          return lastConversation ? lastConversation.id : undefined;
        }
      },
      getPreviousPageParam: (firstPage, pages) => {
        return undefined;
      },
      enabled: isUserRole,
    });

  useEffect(() => {
    const handleResize = () => {
      setViewHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data) {
      dataRef.current = [...data.pages.flatMap((page) => page)];
    }
  }, [data]);

  const selectNewConversationCreated = (conv?: any) => {
    if (conv) {
      setSelectedConversation(conv);
    } else if (dataRef.current) {
      setSelectedConversation(dataRef.current[0]);
      // setcreatedTicket(dataRef.current[0].ticket_flag)
    }
  };

  const handleCreateConversation = () => {
    setSelectedConversation({ id: '' });
    // setcreatedTicket(false)
    scrollToTop();
    // setTimeout(scrollToTop, 100);
  };

  const handleSelectConversation = (conv: any) => {
    setSelectedConversation(conv);
    // setcreatedTicket(isCreatedTicket)
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight <=
      event.currentTarget.scrollTop + event.currentTarget.clientHeight + 100;

    if (bottom && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  const scrollToTop = () => {
    if (conversationListRef.current) {
      conversationListRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handleClickMenuButton = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  let content;

  if (isFetching) {
    content = <Typography variant="h4">Loading...</Typography>;
  }

  if (error) {
    content = <Typography variant="h4">{error?.message}</Typography>;
  }

  if (isUserRole && data) {
    content = (
      <Box
        ref={conversationListRef}
        onScroll={handleScroll}
        sx={{ overflowY: 'auto' }}
      >
        <ConversationList
          conversations={[...data.pages.flatMap((page) => page)]}
          selectedConversation={selectedConversation}
          onSelectConversation={handleSelectConversation}
          handleCreateConversation={handleCreateConversation}
        />
      </Box>
    );
  }

  if (!isUserRole) content = null;

  return (
    <Box
      sx={{
        width: '100%',
        height: `${viewHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        overflowX: 'hidden',
        minWidth: 0,
        maxWidth: '100vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 1,
              position: 'absolute',
              width: '100%',
              height: 50,
            }}
          >
            {isUserRole ? (
              <IconButton onClick={handleCreateConversation}>
                <img
                  src="/new_conversation.svg" // Reference your SVG file from the public folder
                  alt="icon"
                  style={{ width: 18, height: 18 }} // Adjust the size as needed
                />
              </IconButton>
            ) : null}
            {isUserRole ? (
              <IconButton onClick={handleClickMenuButton}>
                <img
                  src="/menu.svg" // Reference your SVG file from the public folder
                  alt="icon"
                  style={{ width: 24, height: 24 }} // Adjust the size as needed
                />
              </IconButton>
            ) : null}
          </Box>
        ) : isUserRole ? (
          <Box
            sx={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              background: isDarkMode
                ? theme.palette.background.default
                : '#f5f5f5',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  p: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={handleCreateConversation}>
                  <img
                    src="/new_conversation.svg" // Reference your SVG file from the public folder
                    alt="icon"
                    style={{ width: 32, height: 32 }} // Adjust the size as needed
                  />
                </IconButton>
              </Box>
              {content}
            </Box>
            <UserInfo user={user} />
          </Box>
        ) : null}

        {/* Drawer for mobile */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={handleCloseDrawer}>
          <Box
            sx={{
              width: '250px',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 1,
                  width: '100%',
                  height: 50,
                }}
              >
                <IconButton onClick={handleCreateConversation}>
                  <img
                    src="/new_conversation.svg" // Reference your SVG file from the public folder
                    alt="icon"
                    style={{ width: 32, height: 32 }} // Adjust the size as needed
                  />
                </IconButton>
                {isUserRole ? (
                  <IconButton onClick={handleCloseDrawer}>
                    <img
                      src="/menu.svg" // Reference your SVG file from the public folder
                      alt="icon"
                      style={{ width: 24, height: 24 }} // Adjust the size as needed
                    />
                  </IconButton>
                ) : null}
              </Box>
              {content}
            </Box>
            <UserInfo user={user} />
          </Box>
        </Drawer>
        <ConversationChat
          key={selectedConversation?.id}
          selectNewConversationCreated={(conv: any) =>
            selectNewConversationCreated(conv)
          }
          conversation={selectedConversation}
        />
      </Box>
    </Box>
  );
};

export default ChatBox;
