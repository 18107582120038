import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  styled,
  useTheme,
} from '@mui/material';
import { colors } from '../../ColorModeProvider';
import { DashedDivider } from '../../ui/DashedDivider';
export enum TicketStatus {
  PENDING = 'Pending',
  SOLVED = 'Solved',
}
interface TicketProps {
  id: string;
  description: string;
  creationDate: string;
  resolveDate?: string;
  status: TicketStatus;
}

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '95%',
  marginTop: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  borderColor: colors.border.grey,
}));

const TicketComponent = ({
  id,
  description,
  creationDate,
  resolveDate,
  status,
}: TicketProps) => {
  const theme = useTheme();
  return (
    <div className="ticket-container">
      <Typography
        variant="h6"
        color="text.secondary"
        sx={{
          fontSize: '1.2rem',
          fontWeight: 400,
          color: theme.palette.common.black,
        }}
      >
        🎉 Thank you, request submitted!
      </Typography>
      <StyledCard variant="outlined">
        <CardContent
          sx={{
            padding: 0,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            sx={{
              padding: theme.spacing(1),
              backgroundColor: colors.background,
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <img src="/file-icon.svg" alt="file-icon" />
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: 'center' }}
              >
                #{id}
              </Typography>
            </Box>
            <Chip
              label={status}
              size="small"
              sx={{
                borderRadius: theme.spacing(0.75),
                '& .MuiChip-label': {
                  px: 2,
                },
                backgroundColor:
                  status === TicketStatus.SOLVED
                    ? colors.ticket.success.background
                    : colors.ticket.pending.background,
                color:
                  status === TicketStatus.SOLVED
                    ? colors.ticket.success.text
                    : colors.ticket.pending.text,
              }}
            />
          </Box>

          <Typography variant="body1" mb={1}>
            {description}
          </Typography>
          <DashedDivider />
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Creation date: {creationDate}
            </Typography>
            {status === TicketStatus.SOLVED && resolveDate && (
              <Typography variant="body2" color="text.secondary">
                Resolve date: {resolveDate}
              </Typography>
            )}
          </Box>
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default TicketComponent;
