import { useEffect, useState } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import MicOffIcon from '@mui/icons-material/MicOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

interface VoiceConversationActionsProps {
  startRecording: () => Promise<void>;
  stopRecording: () => void;
  stopSpeech: () => void;
  onMute: () => void;
  isMuted: boolean;
}
export default function VoiceConversationActions(
  props: VoiceConversationActionsProps
) {
  const { startRecording, stopRecording, stopSpeech, onMute, isMuted } = props;

  const [record, setRecord] = useState(true);
  const [play, setPlay] = useState(true);

  useEffect(() => {
    startRecording();
    return () => {
      stopRecording();
    };
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          width: '100%',
        }}
      >
        <Tooltip title={record ? 'Start Recording' : 'Stop Recording'}>
          <IconButton
            onClick={() => {
              setRecord(!record);
              if (record) {
                stopRecording();
              } else {
                startRecording();
              }
            }}
            size="large"
            sx={{
              color: record ? 'inherit' : '#C8322B',
              backgroundColor: record ? '#f5f5f5' : '#ffefee',
              '&:hover': {
                backgroundColor: record ? '#f5f5f5' : '#ffefee',
              },
            }}
          >
            {record ? <MicIcon /> : <MicOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={play ? 'Start Chat' : 'Pause Chat'}>
          <IconButton
            onClick={() => {
              setPlay(!play);
              if (play) {
                onMute();
                stopSpeech();
              } else {
                // startSpeech();
              }
            }}
            size="large"
            sx={{
              color: play ? 'inherit' : '#ffffff',
              backgroundColor: play ? '#f5f5f5' : '#c8322b',
              '&:hover': {
                backgroundColor: play ? '#f5f5f5' : '#c8322b',
              },
            }}
          >
            {play ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          color: '#777B84',
        }}
      >
        <Typography variant="body1">Supporting English & French</Typography>
      </Box>
    </Box>
  );
}
