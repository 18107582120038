import { useState, useRef, useCallback, useEffect } from 'react';
import {
  TranscribeStreamingClient,
  StartStreamTranscriptionCommand,
  AudioStream,
} from '@aws-sdk/client-transcribe-streaming';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

// AWS Configuration
const REGION = process.env.REACT_APP_AWS_REGION || 'us-east-1';

// Ensure the Identity Pool ID matches the correct format (region:uuid)
const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;

const LANGUAGE_CODE = 'en-US';
const SAMPLE_RATE = 16000;

// Validate Identity Pool ID format
const isValidIdentityPoolId = (id: string): boolean => {
  const pattern = /^[\w-]+:[0-9a-f-]+$/;
  return pattern.test(id);
};

interface AudioState {
  audioContext: AudioContext | null;
  audioInput: MediaStreamAudioSourceNode | null;
  processor: ScriptProcessorNode | null;
  stream: MediaStream | null;
}

interface UseAwsTranscribeReturn {
  transcript: string;
  error: string;
  recording: boolean;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
}

export function useAwsTranscribe(): UseAwsTranscribeReturn {
  // Refs for internal state management
  const audioStateRef = useRef<AudioState>({
    audioContext: null,
    audioInput: null,
    processor: null,
    stream: null,
  });
  const transcribeClientRef = useRef<TranscribeStreamingClient | null>(null);
  const audioChunksRef = useRef<ArrayBuffer[]>([]);
  const isRecordingRef = useRef(false);
  const transcriptionTextRef = useRef({
    partial: '',
    final: [] as string[],
  });

  // State for external consumption
  const [transcriptState, setTranscriptState] = useState('');
  const [errorState, setErrorState] = useState('');
  const [isRecording, setIsRecording] = useState(false);

  const cleanupAudioResources = useCallback(() => {
    if (audioStateRef.current.processor && audioStateRef.current.audioInput) {
      audioStateRef.current.processor.disconnect();
      audioStateRef.current.audioInput.disconnect();
    }

    if (audioStateRef.current.audioContext) {
      audioStateRef.current.audioContext.close().catch(console.error);
    }

    if (audioStateRef.current.stream) {
      audioStateRef.current.stream.getTracks().forEach((track) => track.stop());
    }

    audioStateRef.current = {
      audioContext: null,
      audioInput: null,
      processor: null,
      stream: null,
    };
  }, []);

  const updateTranscriptState = useCallback(() => {
    const { partial, final } = transcriptionTextRef.current;
    const fullText = final.join(' ') + (partial ? ' ' + partial : '');
    setTranscriptState(fullText.trim());
  }, []);

  const getAudioStream = useCallback(
    async function* (): AsyncGenerator<AudioStream> {
      while (isRecordingRef.current) {
        if (audioChunksRef.current.length > 0) {
          const chunk = audioChunksRef.current.shift();
          if (chunk) {
            yield {
              AudioEvent: {
                AudioChunk: new Uint8Array(chunk),
              },
            } as AudioStream;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
    },
    []
  );

  const handleTranscriptionResult = useCallback(
    (result: any) => {
      if (!result?.Alternatives?.[0]?.Transcript) return;

      const transcriptText = result.Alternatives[0].Transcript.trim();
      if (!transcriptText) return;

      if (result.IsPartial) {
        transcriptionTextRef.current.partial = transcriptText;
      } else {
        transcriptionTextRef.current.final.push(transcriptText);
        transcriptionTextRef.current.partial = '';
      }
      updateTranscriptState();
    },
    [updateTranscriptState]
  );

  const startTranscription = useCallback(async () => {
    if (!transcribeClientRef.current) return;

    try {
      const command = new StartStreamTranscriptionCommand({
        LanguageCode: LANGUAGE_CODE,
        MediaEncoding: 'pcm',
        MediaSampleRateHertz: SAMPLE_RATE,
        AudioStream: getAudioStream(),
      });

      const response = await transcribeClientRef.current.send(command);

      if (response.TranscriptResultStream) {
        for await (const event of response.TranscriptResultStream) {
          if (!isRecordingRef.current) break;

          const results = event.TranscriptEvent?.Transcript?.Results;
          if (results?.[0]) {
            handleTranscriptionResult(results[0]);
          }
        }
      }
    } catch (error: any) {
      console.error('Transcription error:', error);
      setErrorState(error.message || 'Transcription failed');
    }
  }, [getAudioStream, handleTranscriptionResult]);

  const initializeTranscribeClient = useCallback(() => {
    try {
      if (!IDENTITY_POOL_ID) {
        throw new Error('IDENTITY_POOL_ID is not defined');
      }

      if (!isValidIdentityPoolId(IDENTITY_POOL_ID)) {
        throw new Error('Invalid Identity Pool ID format');
      }

      // Create Cognito client first
      const cognitoClient = new CognitoIdentityClient({
        region: REGION,
      });

      // Create credentials provider
      const credentialsProvider = fromCognitoIdentityPool({
        client: cognitoClient,
        identityPoolId: IDENTITY_POOL_ID,
      });

      // Initialize Transcribe client with credentials
      transcribeClientRef.current = new TranscribeStreamingClient({
        region: REGION,
        credentials: credentialsProvider,
      });

      return true;
    } catch (error: any) {
      console.error('Failed to initialize Transcribe client:', error);
      setErrorState(
        `Transcription service initialization failed: ${error.message}`
      );
      return false;
    }
  }, []);

  const startRecording = useCallback(async () => {
    try {
      setErrorState('');
      transcriptionTextRef.current = { partial: '', final: [] };
      audioChunksRef.current = [];
      updateTranscriptState();

      if (!initializeTranscribeClient()) return;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { channelCount: 1, sampleRate: SAMPLE_RATE },
      });

      const audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)({
        sampleRate: SAMPLE_RATE,
      });

      const audioInput = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(1024, 1, 1);

      processor.onaudioprocess = (e) => {
        if (!isRecordingRef.current) return;
        const float32Array = e.inputBuffer.getChannelData(0);
        const pcmData = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
          pcmData[i] = Math.max(-1, Math.min(1, float32Array[i])) * 0x7fff;
        }
        audioChunksRef.current.push(pcmData.buffer);
      };

      audioInput.connect(processor);
      processor.connect(audioContext.destination);

      audioStateRef.current = {
        audioContext,
        audioInput,
        processor,
        stream,
      };

      isRecordingRef.current = true;
      setIsRecording(true);
      startTranscription();
    } catch (error: any) {
      console.error('Failed to start recording:', error);
      setErrorState('Failed to access microphone');
      stopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeTranscribeClient, startTranscription, updateTranscriptState]);

  const stopRecording = useCallback(() => {
    isRecordingRef.current = false;
    setIsRecording(false);
    cleanupAudioResources();

    // Add any remaining partial text as final
    if (transcriptionTextRef.current.partial) {
      transcriptionTextRef.current.final.push(
        transcriptionTextRef.current.partial
      );
      transcriptionTextRef.current.partial = '';
      updateTranscriptState();
    }
  }, [cleanupAudioResources, updateTranscriptState]);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, [stopRecording]);

  return {
    transcript: transcriptState,
    error: errorState,
    recording: isRecording,
    startRecording,
    stopRecording,
  };
}
