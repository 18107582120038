import React, { useRef, useEffect, useState, KeyboardEvent } from 'react';
import { IconButton, InputBase, Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useColorMode, colors } from '../ColorModeProvider';
import RecordingButton from './RecordingButton';
import { useAwsTranscribe } from '../hooks/useAwsTranscribe';
import VoiceConversation from './voiceConversation/VoiceConversation';
// Declare Web Speech API types
declare global {
  interface Window {
    webkitSpeechRecognition: any;
    SpeechRecognition: any;
  }
}
interface InputBarProps {
  onSendMessage: (message: string) => void;
  disabled?: boolean;
  conversationId: string;
  inputRef: any;
  notAllowContinueConversation?: boolean;
  content?: any;
  messages?: any[];
}

const InputBar: React.FC<InputBarProps> = ({
  inputRef,
  conversationId,
  notAllowContinueConversation,
  onSendMessage,
  disabled = false,
  content,
  messages,
}) => {
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';
  const { transcript, recording, startRecording, stopRecording } =
    useAwsTranscribe();
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  // Focus input on conversation change
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [conversationId]);

  // Update message when transcript changes during recording
  useEffect(() => {
    if (recording && transcript) {
      setMessage(transcript);
    }
  }, [transcript, recording]);

  const handleSendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (message.trim() && !isSending) {
      setIsSending(true);
      const currentMessage = message;
      setTimeout(() => {
        onSendMessage(currentMessage);
        setMessage('');
        setIsSending(false);
      }, 50);
    }
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    } else if (event.key === 'Enter' && event.shiftKey) {
      setMessage((prev) => prev + '\n');
      event.preventDefault();
    }
  };

  return (
    <Box
      ref={formRef}
      component="form"
      onSubmit={handleSendMessage}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: isDarkMode ? colors.grey[600] : '#fff',
        borderRadius: '24px',
        border: '1px solid #e0e0e0',
        margin: '0 24px  24px',
      }}
    >
      <Box
        sx={{
          p: '12px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          opacity: disabled ? 0.5 : 1,
          maxHeight: '250px',
          ml: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '100%',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <InputBase
            inputRef={inputRef}
            multiline
            sx={{
              flex: 1,
              width: '100%',
              resize: 'none',
              fontSize: 14,
              padding: 0,
              '&::placeholder': {
                color: '#AFB4BB',
              },
            }}
            placeholder={
              notAllowContinueConversation
                ? 'This conversation cannot be continued because a ticket has been created.'
                : 'Ask anything'
            }
            inputProps={{
              'aria-label': 'Type a message',
              style: { lineHeight: '1' },
            }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />

          {message ? (
            <Box>
              <IconButton
                aria-label="clear-input"
                onClick={() => setMessage('')}
                sx={{ p: '7px' }}
              >
                <Close sx={{ fontSize: 14, color: '#000' }} />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          minHeight: '50px',
          alignItems: 'center',
          alignSelf: 'flex-end',
        }}
      >
        {/* <RecordingButton
          disabled={disabled}
          isRecording={recording}
          onClick={recording ? stopRecording : startRecording}
        /> */}
        <VoiceConversation content={content} conversationId={conversationId} />
        <IconButton
          type="submit"
          sx={{ p: '10px' }}
          aria-label="send"
          disabled={disabled}
        >
          <img
            src="/send-icon.svg"
            alt="send"
            style={{ width: 32, height: 32 }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default InputBar;
